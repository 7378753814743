import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import withPageEntry from '../../withPageEntry';
import * as NavUtil from '../../Utils/NavUtil';
import ImageCarousel from '../../Components/ImageCarousel';

function About(props) {
  const [dimension] = useOutlet('dimension');
  const [about] = useOutlet('about');
  useOutlet('footerConfig');
  const footerConfig = NavUtil.getFooterConfig(props);
  const isMobile = dimension.rwd === 'mobile';
  const images = (about?.images || []).map((image) => ({
    url: image.expected_url,
  }));

  return (
    <Wrapper id="rev-AboutPage" rwd={isMobile}>
      {about && (
        <div className="content">
          <div className="top">
            <ImageCarousel
              images={images}
              autoPlay={true}
              interval={2000}
              showThumbs={false}
              showIndicators={true}
              itemExtraCss={`border-radius: 10px;`}
              renderIndicator={(clickHandler, isSelected, index, label) => (
                <div
                  className={`indicator ${isSelected ? 'active' : 'inactive'}`}
                  onClick={clickHandler}
                />
              )}
            />
          </div>
          <div className="medium">
            <h2>{about.title}</h2>
            <p>{about.description}</p>
          </div>
          <div className="bottom">
            <div className="contact">
              {footerConfig && renderContactInfos(footerConfig)}
            </div>
            <div className="social">
              {footerConfig && renderSocailInfos(footerConfig)}
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

const renderContactInfos = (footerConfig) => {
  let contact_infos = [];

  if (footerConfig.contact_addr) {
    contact_infos.push(footerConfig.contact_addr);
  }

  if (footerConfig.contact_time) {
    contact_infos.push(footerConfig.contact_time);
  }

  if (footerConfig.contact_email) {
    contact_infos.push(footerConfig.contact_email);
  }

  if (footerConfig.company_name) {
    contact_infos.push(`公司名稱｜${footerConfig.company_name}`);
  }

  if (footerConfig.gui_number) {
    contact_infos.push(`統一編號｜${footerConfig.gui_number}`);
  }

  return contact_infos.map((contact) => <div>{contact}</div>);
};

const renderSocailInfos = (footerConfig) => {
  let social_infos = [];

  if (footerConfig.social_fb) {
    social_infos.push({
      url: footerConfig.social_fb,
      display: 'Facebook',
    });
  }

  if (footerConfig.social_ig) {
    social_infos.push({
      url: footerConfig.social_ig,
      display: 'Instgram',
    });
  }

  if (footerConfig.social_line) {
    social_infos.push({
      url: footerConfig.social_line,
      display: 'Line',
    });
  }

  return social_infos.map((social, index) => {
    return (
      <>
        {index !== 0 && <span className="bar">｜</span>}
        <a href={social.url} target="_blank" rel="noreferrer">
          {social.display}
        </a>
      </>
    );
  });
};

const Wrapper = styled.div`
  background-color: #f6f6f6;
  padding-top: var(--topNavBarHeight);
  min-height: var(--contentMinHeight);
  padding: ${(props) =>
    props.rwd
      ? `calc(var(--topNavBarHeight) + 22px) 20px 40px 20px`
      : `calc(var(--topNavBarHeight) + 60px) 50px 40px 50px`};

  & > .content {
    margin: 0 auto;
    max-width: var(--contentMaxWidth);
    width: 100%;

    & > .top {
      margin-bottom: 57px;

      & .carousel.carousel-slider {
        ${(props) => (props.rwd ? 'overflow: visible;' : '')}
      }

      & .carousel .control-dots {
        display: flex;
        justify-content: center;
        ${(props) => (props.rwd ? 'bottom: -40px;' : '')}
      }

      & .indicator {
        margin: 5px;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        cursor: pointer;

        &.active {
          background-color: var(--primaryColor);
        }

        &.inactive {
          background-color: #ffffff;
        }
      }
    }

    & > .medium {
      margin-bottom: 70px;

      & > h2 {
        margin-bottom: 30px;
        font-size: ${(props) => (props.rwd ? '20px' : '28px')};
        color: #4d4d4d;
      }

      & > p {
        white-space: pre-wrap;
        line-height: 1.94;
        font-size: 16px;
        color: #313131;
      }
    }

    & > .bottom {
      margin-bottom: 50px;
      border-top: 1px solid #b5b5b5;
      padding-top: 30px;
      line-height: 1.71;
      font-size: 14px;
      color: #707070;

      & > div.contact {
        margin-bottom: 30px;
      }

      & > div.social {
        & .bar {
          margin: 0px 5px;
        }
      }
    }
  }
`;

export default withPageEntry(About);
